.embed-container {

    border: 0;
    height: 1000px;
    width: 100%;
    border: none !important;

    border-width: 0px !important;
    border-style: inset !important;
    border-color: transparent !important;
    border-image: initial;    
}

iframe {  border: none !important; }